import { Component } from '@angular/core';
import {
  CardCheckedLabelComponent
} from "@app/shared/components/roam-card/card-checked-label/card-checked-label.component";

@Component({
  selector: 'sharing-email',
  standalone: true,
  imports: [
    CardCheckedLabelComponent
  ],
  template: `
    <div class="d-flex gap-24 mt-8">
      <app-card-checked-label [label]="'Association Owner(s)'"
                              [desc]="'Email homeowner(s) and share to Resident Center.'"
      />
      <app-card-checked-label [label]="'Staff'"
                              [desc]="'Email staff members with access to architectural requests.'"
      />
    </div>
  `,
})
export class SharingEmailComponent {

}
